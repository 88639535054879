(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name teams.newTeam.controller:NewTeamCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.teams.newTeam')
    .controller('NewTeamCtrl', NewTeamCtrl);

  function NewTeamCtrl($scope, $mdToast, $filter, $stateParams, $state,
                       categories, genders, programmes, entities,
                       ProgrammeSports, Insurances, EntityTeams) {
    var vm = this;
    vm.ctrlName = 'NewTeamCtrl';
    vm.genders = genders;
    vm.sports = [];
    vm.programs = programmes;
    vm.categories = categories;
    vm.entities = entities;
    vm.lodp = false;
    vm.team = {
      programmeId: vm.programs[0].id
    };
    vm.programmeSelected = false;
    vm.price = {};
    vm.showInsurance = false;

    vm.createTeam = createTeam;
    vm.entitySelected = selectEntity($stateParams.entity);

    $scope.$watch('vm.team.programmeId', function (newID) {
      vm.sports = [];
      if (angular.isDefined(newID)) {
        ProgrammeSports.query({id: newID}, function (sports) {
          vm.sports = sports;
          vm.team.sportId = undefined;
          vm.programmeSelected = true;
        }, function () {
          vm.programmeSelected = false;
        });
      }
    });
    $scope.$watch('vm.team.sportId', function (newID) {
      getInsurancePrice(vm.team.programmeId, newID, vm.team.categoryId);
    });

    $scope.$watch('vm.team.categoryId', function (newID) {
      getInsurancePrice(vm.team.programmeId, vm.team.sportId, newID);
    });

    function getInsurancePrice(programID, sportID, categoryID) {
      if (angular.isDefined(sportID) && angular.isDefined(categoryID)) {
        Insurances.get({idP: programID, idS: sportID, idC: categoryID}, function (price) {
          vm.showInsurance = true;
          vm.price = price;
        });
      } else {
        vm.showInsurance = false;
        vm.price = undefined;
      }
    }

    function selectEntity(entityId) {
      if (vm.entities.length === 1) {
        return vm.entities[0];
      } else {
        return findEntity(entityId)
      }
    }

    function findEntity(entityId) {
      return _.find(entities, function (e) {
        return e.id === entityId;
      });
    }



    function createTeam() {
      EntityTeams.save({entityId: vm.entitySelected.id}, vm.team, function () {
        $state.go('home.teams', {selectedEntity: vm.entitySelected}, {reload: true});
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('TEAM_SUCCESSFUL_CREATE'))
            .position('bottom left')
            .hideDelay(3000)
        );
      }, function (err) {
        var errorMessage;
        if (err.status === 400) {
          errorMessage = 'BAD_REQUEST_TEAM';
        } else if (err.status === 409) {
          errorMessage = 'ERROR_EXISTING_TEAM';
        } else {
          errorMessage = 'SERVER_ERROR';
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(errorMessage))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }


  }
}());
